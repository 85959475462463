import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import StatusAlert from "../../components/alerts/StatusAlert"


import { useState } from "react"



export default function Pages( {data}) {


return (
  <>
  <Layout>
  <Container>
  {isLoggedIn() ? (

 <div className="">
<div>
  <a href={data.airtable.data.Path}><Type type="p6">&lt; Back</Type></a>
  <Type type="p2">{data.airtable.data.Name}</Type>
  <Type type="p3" nopadding="yes">{data.airtable.data.ClientOrganisation}</Type>
  <Type type="p4">{data.airtable.data.ClientContactName}, {data.airtable.data.ClientEmail}</Type>
</div>
<div className="mt-16 grid grid-cols-2 gap-8">

 {data.airtable.data.Insights.map((InsightsArray) => (
   <>
    <div className="border-2  rounded-xl p-8">
      <div className="flex flex-row">
        <div className="w-4/5"><Type type="h3">{InsightsArray.data.InsightOrder}. {InsightsArray.data.Insight}</Type></div>
        <div className="w-1/5">
          <div className="flex justify-end">
            <div className="rounded-full bg-black text-white inline py-1 px-3">{InsightsArray.data.Type}</div>
          </div>
        </div>
      </div>
      <div className="">
        <Type type="h6" nopadding="yes">RECOMMENDATION</Type>
        <Type type="p5">{InsightsArray.data.Recommendation}</Type>
      </div>
      {InsightsArray.data.KeyQuote ?
      <div className="">
        <blockquote className="border-l-8 border-blue bg-blue/20 p-4 mb-2">
          <Type type="p6">"{InsightsArray.data.KeyQuote}"</Type>
          <Type type="h6" nopadding="yes">{InsightsArray.data.ParticipantTag}</Type>
        </blockquote>
        
      </div>
      :
      undefined
    }

    </div>
    
    </>
 ))}


</div>

 </div>
 
    
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </Container>
  </Layout>
    </>

  )
}

export const Head = () => <title>Journey</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Projects" },
    data: { InsightsPath: { eq: $Path } }) {
      data {
        Name
        Path
        ClientContactName
        ClientEmail
        ClientOrganisation
        Insights { data {
          Name
          Insight
          Recommendation
          InsightOrder
          KeyQuote
          ParticipantTag
          Type
          
                  }}
      }
    }
  }
`
