import React from "react"
import Type from "../../components/typography/Type"


const StatusAlert = (props) => {	
	
const status = props.status; 	
		
	switch (status) {
		  
		  case "Complete":
		  return (
	
		  <div className="px-4 py-2 bg-black/30 text-white rounded-full text-center font-bold">{status}</div>

	  );
	  case "Booked":
			return (
		<div className="px-4 py-2 bg-blue rounded-full text-center font-bold">{status}</div>

		);
		case "Cancelled":
			return (
		<div className="px-4 py-2 bg-red text-white rounded-full text-center font-bold">{status}</div>
		
		);
		case "Invited":
			return (
		<div className="px-4 py-2 bg-yellow text-black rounded-full text-center font-bold">{status}</div>
		
		);
		case "Planned":
			  return (
			  <div className="px-4 py-2 bg-yellow rounded-full text-center font-bold">{status}</div>

		  );
	  default:
	  return (
		 <div className="px-4 py-2 bg-yellow rounded-full text-center font-bold">{status}</div>

	  );
	};	
};

export default StatusAlert;
