
import React from "react"
import Type from "../../components/typography/Type"
import { useState } from 'react'

export default function Collapsed(props) {

const children = props.children
const title = props.title
const status = props.status

const [isOpen, toggleOpen]  = useState(false);
const [isClosed, toggleClosed]  = useState(true);

switch (status) {
	
	
case "open":
	return (

<div>
	<div className="flex justify-end">	
		<button onClick={() => toggleClosed(!isClosed)} className="mb-2">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
	  	  		<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
			</svg>
		</button>
	</div>
	<div className={`${isClosed ? `block` : `hidden` } `}>
	<>
	{children}
	</>
	</div>
</div>
);
default:
	return (

<div>
	<div className="flex justify-end">	
		<button onClick={() => toggleOpen(!isOpen)} className="mb-2">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
					<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
			</svg>
		</button>
	</div>
	<div className={`${isOpen ? `block` : `hidden` } `}>
	<>
	{children}
	</>
	</div>
</div>
);
};



}